import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Markdown from 'markdown-to-jsx';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import Section from '../components/Section';
import TitleBlock from '../components/TitleBlock';
import InfoBits from '../components/InfoBits';
import OffsetBlock from '../components/OffsetBlock';

function AboutPage({ data }) {
  const frontMatter = data.allMarkdownRemark.edges[0].node.frontmatter;
  const {
    seo: { title: seoTitle, description: seoDescription },
  } = frontMatter;
  const {
    page,
    bestPractice,
    adminExperience,
    browserTesting,
    performance,
    plugins,
    hosting,
  } = frontMatter;
  return (
    <Layout>
      <SEO title={seoTitle} description={seoDescription} />
      <Helmet>
        <body className="bgColor bgColor--dawnPink" />
      </Helmet>
      <Section large>
        <TitleBlock title={page.title}>
          <Markdown>{page.lead}</Markdown>
        </TitleBlock>
      </Section>
      <Section>
        <OffsetBlock title={bestPractice.title}>
          <Markdown className="bt pb40 content">
            {bestPractice.content}
          </Markdown>
          <InfoBits list={bestPractice.list} />
        </OffsetBlock>
      </Section>
      <Section>
        <OffsetBlock title={adminExperience.title}>
          <Markdown className="bt content">{adminExperience.content}</Markdown>
        </OffsetBlock>
      </Section>
      <Section>
        <OffsetBlock title={browserTesting.title}>
          <Markdown className="bt content">{browserTesting.content}</Markdown>
        </OffsetBlock>
      </Section>
      <Section>
        <OffsetBlock title={performance.title}>
          <Markdown className="bt pb60 content">{performance.content}</Markdown>
          <InfoBits list={performance.list} />
        </OffsetBlock>
      </Section>
      <Section>
        <OffsetBlock title={plugins.title}>
          <Markdown className="bt pb40 content">{plugins.content}</Markdown>
          <InfoBits list={plugins.list} />
        </OffsetBlock>
      </Section>
      <Section>
        <OffsetBlock title={hosting.title}>
          <Markdown className="bt content">{hosting.content}</Markdown>
        </OffsetBlock>
      </Section>
    </Layout>
  );
}

AboutPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }).isRequired,
};

export default AboutPage;

export const query = graphql`
  query {
    allMarkdownRemark(filter: { frontmatter: { slug: { eq: "wordpress" } } }) {
      edges {
        node {
          frontmatter {
            seo {
              title
              description
            }
            page {
              title
              lead
            }
            bestPractice {
              title
              content
              list {
                title
                description
              }
            }
            adminExperience {
              title
              content
            }
            browserTesting {
              title
              content
            }
            performance {
              title
              content
              list {
                title
                description
              }
            }
            plugins {
              title
              content
              list {
                title
                description
              }
            }
            hosting {
              title
              content
            }
          }
        }
      }
    }
  }
`;
